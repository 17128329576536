import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "monitoring"
    }}>{`Monitoring`}</h1>
    <h2 {...{
      "id": "house-of-apps"
    }}>{`House of apps`}</h2>
    <h3 {...{
      "id": "app"
    }}>{`App`}</h3>
    <p>{`The app is on the store under the name "Omgevings app" `}</p>
    <h3 {...{
      "id": "links"
    }}>{`Links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://portal.azure.com/#@8a9ceac8-fbdc-42d5-9a27-4600ecdb730b/dashboard/arm/subscriptions/231ba11f-b167-49ce-bd39-3e76b4f324f8/resourceGroups/houseofapps-weu/providers/Microsoft.Portal/dashboards/65a2b02b-fd7d-48f6-a595-5e5cbab70e48-dashboard"
        }}>{`dashboard`}</a>{`, login with private approved account or use info@hybrit.org.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://omgevingsapp.hybrit.io/"
        }}>{`admin panel`}</a>{`, login with info@hybrit.org, login details in Hybrit Lastpass account.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://play.google.com/store/apps/details?id=nl.houseofapps.omgevingsapp&gl=NL"
        }}>{`Omgevings app play store`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://play.google.com/store/apps/details?id=nl.houseofapps.veiligheidsapp&gl=NL"
        }}>{`Veiligheidsapp play store`}</a></li>
    </ul>
    <h3 {...{
      "id": "repos"
    }}>{`Repos`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/HybrIT-dev/houseofapps_veiligheidsapp"
        }}>{`Veiligheids app`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/HybrIT-dev/houseofapps_mobileapp"
        }}>{`house of apps mobileapp`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/HybrIT-dev/houseofapps-dotnet"
        }}>{`backend`}</a></li>
    </ul>
    <h3 {...{
      "id": "known-issues"
    }}>{`Known issues`}</h3>
    <ul>
      <li parentName="ul">{`get calls to undefined projects, cause not identified.`}</li>
      <li parentName="ul">{`backend errors when calling deleted projects. `}</li>
    </ul>
    <h2 {...{
      "id": "bouwkennis"
    }}>{`Bouwkennis`}</h2>
    <h3 {...{
      "id": "links-1"
    }}>{`Links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://portal.azure.com/#@bouwkennis.nl/resource/subscriptions/00c52ea1-e174-4493-905a-f90979d11b16/resourceGroups/bouwkennis-weu/providers/microsoft.insights/components/production-bouwkennis-weu/overview"
        }}>{`dashboard`}</a>{`, login with info@hybrit.org and make sure you have the right subscription selected`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://portal.azure.com/#@bouwkennis.nl/resource/subscriptions/00c52ea1-e174-4493-905a-f90979d11b16/resourcegroups/bouwkennis-weu/overview"
        }}>{`cluster`}</a></li>
    </ul>
    <h3 {...{
      "id": "repos-1"
    }}>{`Repos`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/HybrIT-dev/bouwkennis-portal-frontend-angular"
        }}>{`front-end`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/HybrIT-dev/bouwkennis-reporting-api-dotnet"
        }}>{`back-end`}</a></li>
    </ul>
    <h2 {...{
      "id": "spock"
    }}>{`S.P.O.C.K.`}</h2>
    <h3 {...{
      "id": "links-2"
    }}>{`Links`}</h3>
    <p>{`Istio comes with optional addons which provide dashboards to monitor the cluster. The
following dashboards are installed:`}</p>
    <p><em parentName="p">{`The dashboards are only available when authenticated with your `}<inlineCode parentName="em">{`@hybrit.org`}</inlineCode>{` account.`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Documentation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`STAGING URL (default root `}<inlineCode parentName="th">{`*.staging.hybrit.io`}</inlineCode>{`)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`PROD URL (default root `}<inlineCode parentName="th">{`*.production.hybrit.io`}</inlineCode>{`)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Kiali`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://istio.io/latest/docs/ops/integrations/kiali/"
            }}>{`Istio kiali`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://kiali.istio.spock.staging.hybrit.io"
            }}>{`kiali STAG`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://kiali.istio.spock.production.hybrit.io"
            }}>{`kiali PROD`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Grafana`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://istio.io/latest/docs/ops/integrations/grafana/"
            }}>{`Istio grafana`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://grafana.istio.spock.hybrit.io"
            }}>{`grafana STAG`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://grafana.istio.spock.production.hybrit.io"
            }}>{`grafana PROD`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Prometheus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://istio.io/latest/docs/ops/integrations/prometheus/"
            }}>{`Istio prometheus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://prometheus.istio.spock.hybrit.io"
            }}>{`prometheus STAG`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://prometheus.istio.spock.production.hybrit.io"
            }}>{`prometheus PROD`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tracing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://istio.io/latest/docs/ops/integrations/jaeger/"
            }}>{`Istio jaeger`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://tracing.istio.spock.hybrit.io"
            }}>{`tracing STAG`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://tracing.istio.spock.production.hybrit.io"
            }}>{`tracing PROD`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "repos-2"
    }}>{`Repos`}</h3>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://docs.dev.hybrit.io/src-projects-s-p-o-c-k-index#repositories"
      }}>{`S.P.O.C.K. project repos`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      